import "./Book.scss";


function Book() {
    return(
        <>
        <a href="#contact" className="book"><aside className="book__icon"></aside>BOOK APPOINTMENT</a>
        </>
    )
}

export default Book;